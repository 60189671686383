import "./mental-health.scss"

import React from "react"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import loadable from "@loadable/component"

// import BannerCall from "../components/BannerCall"
// import Choices from "../components/for-you/Choices"
// import Layout from "../components/Layout"
// import Shelf from "../components/Shelf"
// import Slogan from "../components/Slogan"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getCurrentLanguagePage,
  getGatsbyImage,
  getBackgroundImage,
} from "../plugins/common"
import ComparisonTable from "../components/homepageV2/ComparisonTable"

const BannerCall = loadable(() => import("../components/BannerCall"))
const Choices = loadable(() => import("../components/for-you/Choices"))
const Shelf = loadable(() => import("../components/Shelf"))
const Slogan = loadable(() => import("../components/Slogan"))

const mentalHealthHeaderRightEmpty =
  "../images/mental-health/mentalHealthHeaderRightEmpty.webp"
const mentalHealthHeaderRightFull =
  "../images/mental-health/mentalHealthHeaderRightFull.webp"
const mentalHealthShelfRightFull =
  "../images/mental-health/mentalHealthShelfRighFull.webp"
const mentalHealthRightEmpty =
  "../images/mental-health/mentalHealthRightEmpty.webp"
const mentalHealthBannerRightEmpty =
  "../images/mental-health/mentalHealthBannerRightEmpty.webp"
const mentalHealthBannerRightFull =
  "../images/mental-health/mentalHealthBannerRightFull.webp"
const mentalHealthChoicesLeftFull =
  "../images/mental-health/mentalHealthChoicesLeftFull.webp"

const MentalHealthPage = ({ pageContext, location }) => {
  const { node: data, general, form } = pageContext
  const aleaToken = general.aleaToken
  const headerImage = getGatsbyImage(data.headerImage.image[0])
  const bannerImage = getGatsbyImage(data.banner.background)
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.headerImage.image[0])
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  const comparisonTables = general.allStrapiComparisons?.nodes

  const getComparisonTable = id => {
    if (comparisonTables && comparisonTables.length) {
      return comparisonTables.find(com => com.strapiId === id)
    }
    return null
  }
  //   console.log(data)

  return (
    <div className="mentalHealth">
      <Layout
        props={general}
        meta={data.meta}
        localizations={getAllLocales(data.localizations, data.locale)}
        location={location}
        lang={lang}
        ogImage={ogImage}
        hideNewsletter={true}
        token={aleaToken}
        footerForm={enableFooterForm ? footerForm : null}
      >
        <div className="overflow-hidden">
          <Slogan props={data.headerImage}>
            <div id="right" className="mentalHealthHeader position-relative">
              <div className="imageZoom d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="image imageAni"
                  image={headerImage}
                  alt="alea"
                />
              </div>
              <div className="">
                <StaticImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="rightEmpty position-absolute"
                  src={mentalHealthHeaderRightEmpty}
                  alt=""
                  layout="constrained"
                />
                <StaticImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="rightFull position-absolute"
                  src={mentalHealthHeaderRightFull}
                  alt=""
                  layout="constrained"
                />
              </div>
            </div>
          </Slogan>
          <div className="mentalHealthShelf">
            <div className=" position-relative">
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="leftCopper position-absolute"
                src={mentalHealthShelfRightFull}
                alt=""
                layout="constrained"
              />
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightEmpty position-absolute"
                src={mentalHealthRightEmpty}
                alt=""
                layout="constrained"
              />
              <Shelf
                title=""
                list={data.description}
                startFrom="right"
                mobileStartFrom="text"
              />
            </div>
          </div>
          <BannerCall props={data.banner} bgColor="bg-daffodil">
            <div className="mentalHealthBanner position-relative">
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightEmpty position-absolute"
                src={mentalHealthBannerRightEmpty}
                alt=""
                layout="constrained"
              />
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightFull position-absolute"
                src={mentalHealthBannerRightFull}
                alt=""
                layout="constrained"
              />
              <div className="imageZoom d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="image imageAni position-absolute"
                  image={bannerImage}
                  alt="alea"
                />
              </div>
            </div>
          </BannerCall>

          <div className="mentalHealthChoices position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="leftFull position-absolute"
              src={mentalHealthChoicesLeftFull}
              alt=""
              layout="constrained"
            />
            <Choices
              props={data.products}
              dashColor="bg-copper"
              general={pageContext.general}
              lang={lang}
            />
          </div>
          <ComparisonTable
            lang={lang}
            data={getComparisonTable(data.comparisonTable.comparison.id)}
          />
        </div>
      </Layout>
    </div>
  )
}
export default MentalHealthPage
